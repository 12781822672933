<template>
<div style="position: relative">
  <canvas :id="canvasId"></canvas>
</div>
</template>

<script>
import { v1 } from 'uuid'
import { onMounted, onUnmounted, reactive } from 'vue'
import Chart from 'chart.js/auto'

export default {
  name: 'LineChart',
  props: {
    labels: Array,
    data: Array,
    min: Number,
    max: Number,
    unit: String
  },
  setup (props) {
    const state = reactive({
      chartEl: undefined,
      chartObj: undefined,
      labels: [
        'January',
        'February',
        'March',
        'April',
        'May'
      ],
      datasets: [{
        tension: 0.3,
        borderWidth: 2,
        backgroundColor: 'rgb(2,70,239)',
        borderColor: 'rgb(7,65,229)',
        data: [0, 10, 5, 2, 20]
      }]
    })
    const canvasId = v1()

    onMounted(() => {
      state.chartEl = document.getElementById(canvasId)
      createChart()
    })

    let chart
    function createChart () {
      if (!state.chartEl) {
        state.chartEl = document.getElementById(canvasId)
      }

      if (props.labels && props.data) {
        state.labels = props.labels
        // console.log(props.data)
        state.datasets[0].data = props.data
      }

      chart = (new Chart(
        state.chartEl,
        {
          type: 'line',
          data: {
            labels: state.labels,
            datasets: state.datasets
          },
          options: {
            maintainAspectRatio: false,
            elements: { point: { radius: 0 } },
            scales: {
              y: {
                suggestedMin: props.min || 0,
                suggestedMax: props.max || 100,
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                    return value + props.unit ? value + ' ' + props.unit : value
                  }
                }
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          }
        }
      )
      )
    }
    // watch(
    //   () => props.data,
    //   (data) => {
    //     // update chart
    //     chart.data = data
    //     chart.update()
    //   }
    // )

    // watch([props.labels, props.data], (v) => {
    //   // console.log(v)
    //   changeData()
    // })

    onUnmounted(() => chart.destroy())

    // function changeData () {
    //   // state.chartObj.data.labels = props.labels
    //   // state.chartObj.data.datasets = []
    //   // state.chartObj.data.datasets.push({
    //   //   tension: 0.3,
    //   //   borderWidth: 2,
    //   //   backgroundColor: 'rgb(2,70,239)',
    //   //   borderColor: 'rgb(7,65,229)',
    //   //   data: props.data
    //   // })
    //   // console.log(state.chartObj.data.datasets[0].data)
    //   chart.update()
    //   console.log(chart)
    // }

    return {
      canvasId
      // changeData
    }
  }
}
</script>

<style scoped>

</style>
