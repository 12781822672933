<template>
  <div style=" background-color: #fff; box-shadow: 0 3px 3px 3px #aaa; padding: 16px; border-radius: 10px">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ShadowCard'
}
</script>

<style scoped>

</style>
