<template>
<div>
  <div class="date-card">
    <div class="date">
      <div class ="weekday"> {{getWeekDays(data.start)}} </div>
      <div class="day">{{ getDate(data.start) }}</div>
    </div>
    <div class="info">
      <div class="title"> {{data.summary}}
      </div>
      <div class="time" v-if="data.allDay">{{getFullDate(data.start)}} - {{ getFullDate(data.end) }}</div>
      <div class="time" v-else>{{getTime(data.start)}} - {{ getTime(data.end) }}</div>
    </div>
  </div>
  <div style="border-bottom: 1px solid #bbb; width: 90%;margin: 6px auto"></div>
</div>
</template>

<script>
export default {
  name: 'CalendarListItem',
  props: {
    data: Object
  },
  setup () {
    function getWeekDays (dateStr) {
      const time = new Date(dateStr)
      return time.toString().split(' ')[0]
    }
    function getDate (dateStr) {
      const time = new Date(dateStr)
      return time.getDate()
    }
    function getTime (dateStr) {
      const time = new Date(dateStr)
      return time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
    }
    function getFullDate (dateStr) {
      const time = new Date(dateStr)
      return time.getDate().toString().padStart(2, '0') + '/' + (time.getMonth() + 1).toString().padStart(2, '0')
    }

    return {
      getWeekDays,
      getDate,
      getTime,
      getFullDate
    }
  }
}
</script>

<style scoped>
.date-card {
  /*height:35px;*/
  /*line-height: 35px;*/
  padding:6px 10px;
  border-radius: 5px;
  font-family: -apple-system;
  display:flex;
  gap: 10px;
  overflow: hidden;
}
.date{
  height:50px;
  width:50px;
  background-color: white;
  border-radius: 20%;
  text-align: center;
  min-height: 50px;
  min-width: 50px;
}

.weekday{
  color:crimson;
  font-weight: bold;
  font-size:15px;
  padding-top: 2px ;
  text-transform: uppercase;
}

.day{
  font-size:25px;
  margin-top:-4px;
}

.info{
  padding:3px 0;
  flex-grow: 1;
  min-width: 0;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  color:#000;
}

.title{
  font-size:19px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.time{
  font-size:15px;
  letter-spacing: 1px;
}

</style>
