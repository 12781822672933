<template>
<main-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  height: 100%;
  overflow: hidden;
  background-color: rgba(200,200,200,0.8);
  padding: 16px;
}

</style>
<script>
import MainView from '@/views/MainView'
export default {
  components: { MainView }
}
</script>
