<template>
<shadow-card style="display: flex;flex-direction: column; gap: 6px; position: relative" v-if="tramData && tramData.data" @click="forceRefresh">

  <div style="position: absolute; width: 100%; height: 100%; background-color: rgba(100,100,100,0.2); top: 0; left: 0" v-if="hover"></div>

  <div style="position: absolute; top:5px; right: 24px;color:#999;font-style: italic">Last Refresh: {{lastRefreshStr}}</div>

  <div style="display: flex; border: 1px solid #aaa;  margin-top: 10px">
    <div style="background-color: #00C1D5; width: 50px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
      57
    </div>
    <div style="display: flex; flex-direction: column-reverse;flex-grow: 1">
      <img src="/img/d2.jpg" alt="tram" style="width: 150px;  margin: 5px auto">
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['57'].first.trustable?'#fff':'#777'}">
      {{ tramData.data['57'].first.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['57'].second.trustable?'#fff':'#777'}">
      {{ tramData.data['57'].second.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['57'].third.trustable?'#fff':'#777'}">
      {{ tramData.data['57'].third.diff }}
    </div>
  </div>

  <div style="display: flex; border: 1px solid #aaa">
    <div style="background-color: #969696; width: 50px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
      58
    </div>
    <div style="display: flex; flex-direction: column-reverse;flex-grow: 1">
      <img src="/img/d2.jpg" alt="tram" style="width: 150px;  margin: 5px auto">
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['58'].first.trustable?'#fff':'#777'}">
      {{ tramData.data['58'].first.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['58'].second.trustable?'#fff':'#777'}">
      {{ tramData.data['58'].second.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['58'].third.trustable?'#fff':'#777'}">
      {{ tramData.data['58'].third.diff }}
    </div>
  </div>

  <div style="display: flex; border: 1px solid #aaa;">
    <div style="background-color: #00653A; width: 50px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
      59
    </div>
    <div style="display: flex; flex-direction: column-reverse;flex-grow: 1">
      <img src="/img/b.jpg" alt="tram" style="width: 150px;  margin: 5px auto">
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['59'].first.trustable?'#fff':'#777'}">
      {{ tramData.data['59'].first.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['59'].second.trustable?'#fff':'#777'}">
      {{ tramData.data['59'].second.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['59'].third.trustable?'#fff':'#777'}">
      {{ tramData.data['59'].third.diff }}
    </div>
  </div>

  <div style="display: flex; border: 1px solid #aaa">
    <div style="background-color: #8A1B61; width: 50px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
      19
    </div>
    <div style="display: flex; flex-direction: column-reverse;flex-grow: 1">
      <img src="/img/d2.jpg" alt="tram" style="width: 150px;  margin: 5px auto">
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['19'].first.trustable?'#fff':'#777'}">
      {{ tramData.data['19'].first.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['19'].second.trustable?'#fff':'#777'}">
      {{ tramData.data['19'].second.diff }}
    </div>
    <div style="background-color: #000; width: 50px; height: 45px;  display: flex; justify-content: space-around; align-items: center; font-size: 25px" :style="{'color':tramData.data['19'].third.trustable?'#fff':'#777'}">
      {{ tramData.data['19'].third.diff }}
    </div>
  </div>
</shadow-card>
</template>

<script>
import ShadowCard from '@/components/ShadowCard'
import { onMounted, ref } from 'vue'
import { getTramTime } from '@/requests/request'
export default {
  name: 'TramCard',
  components: { ShadowCard },
  setup () {
    const tramData = ref(undefined)
    const lastRefreshStr = ref('')
    const hover = ref(false)
    onMounted(async () => {
      const time = new Date()
      lastRefreshStr.value = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') + ':' + time.getSeconds().toString().padStart(2, '0')
      tramData.value = await getTramTime()
      setInterval(refreshTrams, 30000)
    })

    async function refreshTrams () {
      const res = await getTramTime()

      if (res.data) {
        tramData.value = res
        const time = new Date()
        lastRefreshStr.value = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') + ':' + time.getSeconds().toString().padStart(2, '0')
      }
    }

    async function forceRefresh () {
      hover.value = true
      await refreshTrams()
      hover.value = false
    }

    return {
      tramData,
      lastRefreshStr,
      hover,
      forceRefresh
    }
  }
}
</script>

<style scoped>

</style>
