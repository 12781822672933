<template>
  <shadow-card v-if="state.hourPercents.length" style="position: relative" @click="forceRefresh">
    <div style="position: absolute; width: 100%; height: 100%; background-color: rgba(100,100,100,0.2); top: 0; left: 0" v-if="hover"></div>
    <line-chart v-if="state.minReady" style="height: 200px; " :min="0" :max="3" :data="state.minutePrecipitations" :labels="state.minuteLables" unit="mm"  />
    <line-chart v-if="state.minReady" style="height: 200px;" :data="state.hourPercents" :labels="state.hourLabels" unit="%"  />

    <div style="display: flex; justify-content: space-between; height: 150px; margin-top: 5px" >
      <div v-for="(item,index) in state.dayArray" :key="item+index" style="border: #aaa 1px solid; font-size: 13px; height: 100%;border-radius: 10px;padding: 10px">
        <div>
          {{item.day}} {{item.date}}
        </div>
        <div>{{item.feels_like.day}}°</div>
        <div>{{item.weather[0].description}}</div>
        <img :src="`http://openweathermap.org/img/wn/${item.weather[0].icon}@2x.png`" :alt="item.weather[0].description" style="width: 50px">
        <div>{{Math.round(item.pop*100)}}%</div>
      </div>
    </div>
  </shadow-card>
</template>

<script>

// import { weatherData } from '@/data/weatherData'
import { onMounted, reactive, ref } from 'vue'
import LineChart from '@/components/LineChart'
import ShadowCard from '@/components/ShadowCard'
import { getWeatherData } from '@/requests/request'
// import axios from 'axios'

export default {
  name: 'WeatherCard',
  components: { ShadowCard, LineChart },
  setup () {
    const state = reactive({
      minuteLables: [],
      minutePrecipitations: [],
      hourLabels: [],
      hourPercents: [],
      dayArray: [],
      minReady: true
    })

    let weatherData

    onMounted(async () => {
      await refreshWeatherData()
      setInterval(refreshWeatherData, 1000 * 60 * 30)
    })

    async function refreshWeatherData () {
      state.minReady = false
      const res = await getWeatherData()
      if (res.timezone) {
        console.log('==================')
        console.log(res)
        console.log('=================')
        weatherData = res
        formatWeatherData()
      }
      state.minReady = true
    }

    const hover = ref()

    async function forceRefresh () {
      hover.value = true
      await refreshWeatherData()
      hover.value = false
    }

    function formatWeatherData () {
      state.minuteLables = []
      state.minutePrecipitation = []
      for (const i of weatherData.minutely) {
        const time = new Date(i.dt * 1000)
        const timeStr = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
        state.minuteLables.push(timeStr)
        state.minutePrecipitations.push(i.precipitation)
      }

      state.hourLabels = []
      state.hourPercents = []
      for (const j of weatherData.hourly) {
        const time = new Date(j.dt * 1000)
        const timeStr = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
        state.hourLabels.push(timeStr)
        state.hourPercents.push(j.pop * 100)
      }

      state.dayArray = []
      for (const k of weatherData.daily) {
        const time = new Date(k.dt * 1000)
        const timeStr = time.getDate().toString().padStart(2, '0') + '/' + (time.getMonth() + 1).toString().padStart(2, '0')

        state.dayArray.push({
          date: timeStr,
          day: time.toString().split(' ')[0],
          ...k
        })
      }
    }

    return {
      state,
      forceRefresh,
      hover
    }
  }
}
</script>

<style scoped>

</style>
