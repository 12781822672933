<template>
<shadow-card>
  <div style="font-size: 30px;">
    {{ state.hour }}{{ state.sec?':':' ' }}{{ state.min }}
  </div>
</shadow-card>
</template>

<script>
import ShadowCard from '@/components/ShadowCard'
import { onMounted, reactive } from 'vue'
export default {
  name: 'TimeCard',
  components: { ShadowCard },
  setup () {
    const state = reactive({
      min: '0',
      hour: '0',
      sec: true
    })
    onMounted(() => {
      setInterval(setTime, 500)
    })

    function setTime () {
      state.sec = !state.sec
      const time = new Date()
      state.min = time.getMinutes().toString().padStart(2, '0')
      state.hour = time.getHours().toString().padStart(2, '0')
    }
    return {
      state
    }
  }
}
</script>

<style scoped>

</style>
