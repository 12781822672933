<template>
<shadow-card style="position: relative" @click="forceRefresh"  v-if="fuelData && fuelData.data">
  <div style="position: absolute; width: 100%; height: 100%; background-color: rgba(100,100,100,0.2); top: 0; left: 0" v-if="hover"></div>
  <div style="position: absolute; top:5px; right: 24px;color:#999;font-style: italic"> {{  fuelData.data.lastUpdate}}  </div>
  <div style="margin-top: 10px">

    <div style="display: flex; justify-content: space-between; border: 1px solid #aaa; margin-top: 10px">
      <div style="background-color: #03356d; width: 70px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        U91
      </div>

      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u91'][0] }}
      </div>
      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u91'][1] }}
      </div>
      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u91'][2] }}
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; border: 1px solid #aaa; margin-top: 10px">
      <div style="background-color:#c5870f; width: 70px; height: 45px; color: #ffffff; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        U95
      </div>

      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u95'][0] }}
      </div>
      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u95'][1] }}
      </div>
      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u95'][2] }}
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; border: 1px solid #aaa; margin-top: 10px">
      <div style="background-color:#AE0028; width: 70px; height: 45px; color: #ffffff; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        U98
      </div>

      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u98'][0] }}
      </div>
      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u98'][1] }}
      </div>
      <div style="background-color: #000; width: 120px; height: 45px; color: white; display: flex; justify-content: space-around; align-items: center; font-size: 25px">
        {{ fuelData.data['u98'][2] }}
      </div>
    </div>

  </div>
</shadow-card>
</template>

<script>
import ShadowCard from '@/components/ShadowCard'
import { onMounted, ref } from 'vue'
import { getFuelPrice } from '@/requests/request'
export default {
  name: 'FuelCard',
  components: { ShadowCard },
  setup () {
    const fuelData = ref()
    const lastRefreshStr = ref('')
    const hover = ref(false)
    onMounted(async () => {
      const time = new Date()
      lastRefreshStr.value = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
      fuelData.value = await getFuelPrice()
      setInterval(refreshFuelPrice, 1000 * 60 * 60 * 6)
    })

    async function refreshFuelPrice () {
      const res = await getFuelPrice()
      if (res.data) {
        fuelData.value = res
        const time = new Date()
        lastRefreshStr.value = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
      }
    }
    async function forceRefresh () {
      hover.value = true
      await refreshFuelPrice()
      hover.value = false
    }

    return {
      fuelData,
      lastRefreshStr,
      hover,
      forceRefresh
    }
  }
}
</script>

<style scoped>

</style>
