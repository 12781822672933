// import axios from 'axios'

import axios from 'axios'
import CryptoJS from 'crypto-js'

export async function getCalendarEvents () {
  try {
    const { data } = await axios.get('https://api.weilaimg.com/check/calendars')
    return data
  } catch (e) {
    console.log(e)
    return {}
  }
}

export async function postHVACMode (mode) {
  try {
    const { data } = await axios.post('https://api.weilaimg.com/control/PeelStreet/LivingRoom/HVAC/OnOff', {
      value: mode
    })
    return data
  } catch (e) {
    console.log(e)
    return {}
  }
}
export async function postTv () {
  try {
    const { data } = await axios.post('https://api.weilaimg.com/control/PeelStreet/LivingRoom/TV/OnOff', {
      value: 'true'
    })
    return data
  } catch (e) {
    console.log(e)
    return {}
  }
}

export async function getTramTime () {
  try {
    console.log('https://timetableapi.ptv.vic.gov.au/v3/departures/route_type/1/stop/3122/route/887?direction_id=14&max_results=3&devid=3002234&signature=' + CryptoJS.HmacSHA1('/v3/departures/route_type/1/stop/3122/route/887?direction_id=14&max_results=3&devid=3002234', '1679eb96-019c-4441-afe8-2f65ee881bca').toString())
    // throw new Error('test')
    // eslint-disable-next-line camelcase
    const resAll = await axios.all([
      axios.get('https://timetableapi.ptv.vic.gov.au/v3/departures/route_type/1/stop/3122/route/887?direction_id=13&max_results=3&devid=3002234&signature=' + CryptoJS.HmacSHA1('/v3/departures/route_type/1/stop/3122/route/887?direction_id=13&max_results=3&devid=3002234', '1679eb96-019c-4441-afe8-2f65ee881bca').toString()),
      axios.get('https://timetableapi.ptv.vic.gov.au/v3/departures/route_type/1/stop/3100/route/11529?direction_id=21&max_results=3&devid=3002234&signature=' + CryptoJS.HmacSHA1('/v3/departures/route_type/1/stop/3100/route/11529?direction_id=21&max_results=3&devid=3002234', '1679eb96-019c-4441-afe8-2f65ee881bca').toString()),
      axios.get('https://timetableapi.ptv.vic.gov.au/v3/departures/route_type/1/stop/2258/route/725?direction_id=13&max_results=3&devid=3002234&signature=' + CryptoJS.HmacSHA1('/v3/departures/route_type/1/stop/2258/route/725?direction_id=13&max_results=3&devid=3002234', '1679eb96-019c-4441-afe8-2f65ee881bca').toString()),
      axios.get('https://timetableapi.ptv.vic.gov.au/v3/departures/route_type/1/stop/2258/route/897?direction_id=13&max_results=3&devid=3002234&signature=' + CryptoJS.HmacSHA1('/v3/departures/route_type/1/stop/2258/route/897?direction_id=13&max_results=3&devid=3002234', '1679eb96-019c-4441-afe8-2f65ee881bca').toString())
    ])

    // console.log(resAll)
    const tramData = {}

    for (const v of resAll) {
      const departures = v.data.departures
      let currentRoute

      if (departures[0].route_id === 887) {
        tramData['57'] = {}
        currentRoute = tramData['57']
      } else if (departures[0].route_id === 11529) {
        tramData['58'] = {}
        currentRoute = tramData['58']
      } else if (departures[0].route_id === 725) {
        tramData['19'] = {}
        currentRoute = tramData['19']
      } else {
        tramData['59'] = {}
        currentRoute = tramData['59']
      }

      const current = new Date()
      if (departures[0].estimated_departure_utc) {
        const t1 = new Date(departures[0].estimated_departure_utc)
        currentRoute.first = {
          diff: Math.round((t1 - current) / 1000 / 60),
          trustable: true
        }
      } else {
        const t1 = new Date(departures[0].scheduled_departure_utc)
        currentRoute.first = {
          diff: Math.round((t1 - current) / 1000 / 60),
          trustable: false
        }
      }

      if (departures[1].estimated_departure_utc) {
        const t1 = new Date(departures[1].estimated_departure_utc)
        currentRoute.second = {
          diff: Math.round((t1 - current) / 1000 / 60),
          trustable: true
        }
      } else {
        const t1 = new Date(departures[1].scheduled_departure_utc)
        currentRoute.second = {
          diff: Math.round((t1 - current) / 1000 / 60),
          trustable: false
        }
      }

      if (departures[2].estimated_departure_utc) {
        const t1 = new Date(departures[2].estimated_departure_utc)
        currentRoute.third = {
          diff: Math.round((t1 - current) / 1000 / 60),
          trustable: true
        }
      } else {
        const t1 = new Date(departures[2].scheduled_departure_utc)
        currentRoute.third = {
          diff: Math.round((t1 - current) / 1000 / 60),
          trustable: false
        }
      }
    }

    return { data: tramData }
    // console.log(resAll)
    // return data
  } catch (e) {
    console.log(e)
    return {}
  }
}

export async function getFuelPrice () {
  try {
    const { data } = await axios.get('https://api.weilaimg.com/check/sevenEleven/json')
    return data
  } catch (e) {
    console.log(e)
    return {}
  }
}

export async function getWeatherData () {
  try {
  // throw new Error('test')
    const { data } = await axios.get('https://api.openweathermap.org/data/3.0/onecall?lat=-37.806537&lon=144.960150&appid=6e76e7cb43c107c8f1f52ae31350935b&units=metric&lang=zh_cn')
    console.log(data)
    return data
  } catch (e) {
    console.log(e)
    return {}
  }
}
