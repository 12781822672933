<template>
<div style=" height: 100%">
  <div style="display: flex; gap: 15px">
  <weather-card style="width: 45%; " />
  <calendar-card style="width: 30%; height: 50vh; overflow: auto;" />
    <div style="width: 25%; display: flex; flex-direction: column; gap: 10px">
      <time-card />
      <tram-card style=" height: 240px" />
      <switch-card />
      <fuel-card />
    </div>
  </div>
</div>
</template>

<script>
import {
  ref
} from 'vue'
import WeatherCard from '@/components/WeatherCard'
import CalendarCard from '@/components/CalendarCard'
import TramCard from '@/components/TramCard'
import FuelCard from '@/components/FuelCard'
import TimeCard from '@/components/TimeCard'
import SwitchCard from '@/components/SwitchCard'

export default {
  name: 'MainView',
  components: { SwitchCard, TimeCard, FuelCard, TramCard, CalendarCard, WeatherCard },
  setup () {
    const i = ref('')
    // onMounted(() => {
    setInterval(() => {
      const t = new Date()
      i.value = t.toString()
    }, 500)
    // })

    return {
      i
    }
  }
}
</script>

<style scoped>

</style>
