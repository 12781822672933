<template>
<shadow-card style="background-color: #eee;position: relative" v-if="eventList && eventList.data" @click="forceRefresh">
  <div style="position: absolute; width: 100%; height: 100%; background-color: rgba(100,100,100,0.2); top: 0; left: 0" v-if="hover"></div>

  <div style="position: absolute; top:5px; right: 24px;color:#999;font-style: italic">Last Refresh: {{lastRefreshStr}}</div>
<div v-for="(event,index) of eventList.data " :key="event+index">
  <calendar-list-item :data="event"/>
</div>
</shadow-card>
</template>

<script>
import ShadowCard from '@/components/ShadowCard'
import { onMounted, ref } from 'vue'
import { getCalendarEvents } from '@/requests/request'
import CalendarListItem from '@/components/CalendarListItem'
export default {
  name: 'CalendarCard',
  components: { CalendarListItem, ShadowCard },
  setup () {
    const eventList = ref([])
    const lastRefreshStr = ref('')
    const hover = ref(false)

    onMounted(async () => {
      const time = new Date()
      lastRefreshStr.value = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
      eventList.value = await getCalendarEvents()
      setInterval(refreshEvents, 1000 * 60 * 30)
    })

    async function refreshEvents () {
      const res = await getCalendarEvents()
      if (res.data) {
        eventList.value = res
        const time = new Date()
        lastRefreshStr.value = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0')
      }
    }

    async function forceRefresh () {
      hover.value = true
      await refreshEvents()
      hover.value = false
    }

    return {
      eventList,
      lastRefreshStr,
      forceRefresh,
      hover
    }
  }
}
</script>

<style scoped>
</style>
