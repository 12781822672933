<template>
<shadow-card class="w-full">
  <div style="display: flex">
<button @click="clickHVAC('1')">
  <div>
  <div style="width: 32px; height: 32px; margin: 0 auto">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>power</title><path d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" /></svg>

  </div>
  <div>客厅强制冷</div>
</div></button>
  <button @click="clickHVAC('2')" >
    <div style="width: 32px; height: 32px; margin: 0 auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>power</title><path d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" /></svg>

    </div>
    客厅弱制冷</button>
  <button @click="clickHVAC('3')">
    <div style="width: 32px; height: 32px; margin: 0 auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>power</title><path d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" /></svg>

    </div>
    客厅舒适</button>
  <button @click="clickHVAC('0')">
    <div style="width: 32px; height: 32px; margin: 0 auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>power</title><path d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" /></svg>

    </div>
    客厅空调关</button>
  </div>
  <div style="margin: 4px 0" >
    <button @click="clickTv()">
      <div style="width: 32px; height: 32px; margin: 0 auto">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>power</title><path d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" /></svg>

      </div>
      电视开关</button>
  </div>
</shadow-card>
</template>

<script>
/* eslint-disable*/
import ShadowCard from '@/components/ShadowCard'
import { postHVACMode, postTv } from '@/requests/request'
export default {
  name: 'SwitchCard',
  components: { ShadowCard },
  setup () {

    async function clickHVAC(mode){
      await postHVACMode(mode)
    }
    async function clickTv(){
      await postTv()
    }

    return {
      clickHVAC,
      clickTv
    }
  }
}
</script>

<style scoped>
button {
  display: inline-block; /* button 默认是inline-block 无法用margin给它居中 */
  padding: 4px 8px;
  margin: 0 4px;
  border-radius: 4px;
  font-size: 6px;
  color: black;
  background: #969696;
  outline: none;
  border: 0;
  cursor: pointer; /* 为了增加用户体验 */

}


</style>
